<template>
  <div class="addview">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item prop="area" :label="$t('area')">
        <el-select v-model="form.area" :placeholder="$t('checkText')">
          <el-option
            v-for="item in areaoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('khgn')">
        <el-input v-model="form.customerName"></el-input>
      </el-form-item>
      <el-form-item prop="salesman" :label="$t('salesman')">
        <el-autocomplete
          value-key="name"
          class="inline-input"
          :fetch-suggestions="salemanSearch"
          v-model="form.salesman"
          @select="handleSelect"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item prop="type" :label="$t('type')">
        <el-select
          v-model="form.type"
          :placeholder="$t('checkText')"
          @change="changeoption"
        >
          <el-option
            v-for="item in problemtypeaoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-popover
          placement="top-start"
          :title="$t('tixing')"
          width="400"
          trigger="hover"
        >
          <div style="white-space: pre-line" v-html="$t('tixingxijie')"></div>
          <i
            slot="reference"
            style="font-size: 26px"
            class="el-icon-question"
          ></i>
        </el-popover>
      </el-form-item>
      <el-form-item :label="$t('platform')">
        <el-select
          class="short"
          v-model="form.platform"
          :placeholder="$t('checkText')"
        >
          <el-option
            v-for="item in pingtaioptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="ownerType !== '1'" :label="$t('personLiable')">
        <el-autocomplete
          value-key="name"
          class="inline-input"
          :fetch-suggestions="techmanSearch"
          v-model="form.personLiable"
          @select="handleSelect"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item
        prop="productModelType"
        :label="$t('productModelType')"
        v-if="form.type !== $t('ptwt') || ownerType === '1'"
      >
        <el-select
          
          v-model="form.productModelType"
          filterable
          :placeholder="$t('checkText')"
        >
          <el-option
            v-for="item in productModelTypeOptions"
            :key="item.name"
            :label="item.label"
            :value="item.name"
          >
          </el-option>
        </el-select>

        <el-input
          v-if="ifshoutian"
          v-model="shoutianjixing"
          :placeholder="$t('inputText')"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('productType')"
        v-if="form.type !== $t('ptwt') || ownerType === '1'"
      >
        <template
          v-if="
            form.productModelType === $t('qtlx') ||
            form.productModelType === $t('zdy')
          "
        >
          <el-select v-model="form.productType" :placeholder="$t('checkText')">
            <el-option
              v-for="item in prodectoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>

        <template v-else>
          <span>{{ showproductType }}</span>
        </template>
      </el-form-item>

      <el-form-item
        :label="$t('networkType')"
        v-if="form.type !== $t('ptwt') || ownerType === '1'"
      >
        <template
          v-if="
            form.productModelType === $t('qtlx') ||
            form.productModelType === $t('zdy')
          "
        >
          <el-select v-model="form.networkType" :placeholder="$t('checkText')">
            <el-option
              v-for="item in netoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <span>{{ form.networkType }}</span>
        </template>
      </el-form-item>

      <el-form-item prop="keyword" :label="$t('keyword')">
        <el-input v-model="form.keyword"></el-input>
      </el-form-item>
      <el-form-item prop="description" :label="$t('description')">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          v-model="form.description"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('customerDescription')"
        prop="customerDescription"
      >
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          v-model="form.customerDescription"
        ></el-input>
      </el-form-item>

      <el-form-item :label="$t('urgentType')">
        <el-radio-group v-model="form.urgentType">
          <el-radio :label="0">{{ $t("di") }}</el-radio>
          <el-radio :label="1">{{ $t("zhong") }}</el-radio>
          <el-radio :label="2">{{ $t("gao") }}</el-radio>
          <el-radio :label="3">{{ $t("jigao") }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('yujiwanchengshijian')">
        <el-col :span="11">
          <el-date-picker
            type="date"
            :placeholder="$t('checkText')"
            v-model="form.date1"
            style="width: 100%"
          ></el-date-picker>
        </el-col>
      </el-form-item>
      <!-- <el-form-item label="当前卡点">
        <el-select v-model="form.stuckPoint" placeholder="请选择">
          <el-option
            v-for="item in difoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题进度">
        <el-input type="textarea" v-model="form.solution"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="备注">
        <el-input type="textarea" v-model="form.description"></el-input>
      </el-form-item> -->
      <el-form-item :label="$t('tupian')">
        <el-col :span="22">
          <el-upload
            action="/api/procedure/technical-support-work-order/uploadImg"
            :headers="uploadHeaders"
            accept=".jpg,.png"
            :limit="5"
            multiple
            list-type="picture"
            :file-list="imageList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleImageRemove"
            :on-success="handleImgUploadSuccess"
          >
            <el-button size="mini" type="primary">{{
              $t("tupiantixing")
            }}</el-button>
          </el-upload>
        </el-col>
      </el-form-item>

      <el-form-item :label="$t('wenjian')">
        <el-col :span="22">
          <el-upload
            action="/api/procedure/technical-support-work-order/uploadFile"
            :headers="uploadHeaders"
            :limit="5"
            :file-list="fileList"
            :on-remove="handleRemove"
            :on-success="handleUploadSuccess"
          >
            <el-button size="mini" type="primary">{{
              $t("dianjishangchuan")
            }}</el-button>
          </el-upload>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button :disabled="cando" type="primary" @click="addorder">{{
          $t("quedingxiugai")
        }}</el-button>
      </el-form-item>
    </el-form>

    <!-- 预览图片 -->
    <el-dialog
      modal-append-to-body
      :modal="false"
      :visible.sync="dialogVisible"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { AddFaeOrderUrl, getUserListByTypeUrl } from "@/api/fae";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
} from "@/options/index";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ownerType: {
      type: String,
      default: null,
    },
    saleslist: {
      default: null,
    },
  },
  data() {
    return {
      showproductType: null,
      cando: false,
      rules: {
        area: [{ required: true, message: this.$t("inputText") }],
        type: [{ required: true, message: this.$t("inputText") }],
        productModelType: [{ required: true, message: this.$t("checkText") }],
        salesman: [{ required: true, message: this.$t("inputText") }],
        keyword: [{ required: true, message: this.$t("inputText") }],
        description: [{ required: true, message: this.$t("inputText") }],
        customerDescription: [
          { required: true, message: this.$t("inputText") },
        ],
      },
      yanfamanlist: [],
      ifshoutian: false,
      shoutianjixing: "",
      dialogImageUrl: "",
      dialogVisible: false,
      imageList: [],
      fileList: [],
      form: {
        area: "",
        customerName: "",
        personLiable: "",
        customerDescription: "",
        description: "",
        keyword: "",
        networkType: "",
        platform: "",
        productModelType: productModelTypeOptions[0].name,
        productType: "",
        salesman: "",
        // solution:'',
        // stuckPoint:'',
        type: "",
        urgentType: 0,
      },
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
    };
  },
  computed: {
    uploadHeaders() {
      const usertoken = JSON.parse(window.localStorage.getItem("usertoken"));
      return { Authorization: usertoken };
    },
  },
  created() {},
  mounted() {},
  watch: {
    "form.productModelType"(_new) {
      /**
       * 选择“机型”自动填写“产品类型”、“网络机制”
       * “其他机型”则手动选择
       */
      console.log(_new);
      console.log(this.$t("zdy"));

      if (_new == this.$t("zdy")) {
        this.ifshoutian = true;
      } else {
        this.ifshoutian = false;

        const { productType, networkType } =
          this.productModelTypeOptions.find(({ name }) => name === _new) ?? {};
        const chooseproduct = this.prodectoptions.find(
          ({ label }) => label === productType
        );

        this.form.productType = chooseproduct.value;
        this.showproductType = productType;
        this.form.networkType = networkType;
      }
    },
    visible: {
      handler(_new) {
        // 业务工单不清楚表单数据
        this.$nextTick(() => {
          if (this.ownerType !== "1") {
            this.form = {
              area: "",
              customerName: "",
              description: "",
              keyword: "",
              networkType: "",
              platform: "",
              personLiable: "",
              productModelType: "",
              productType: "",
              salesman: "",
              // solution:'',
              // stuckPoint:'',
              type: "",
              urgentType: 0,
            };
          }
          this.imageList = [];
          this.fileList = [];

          if (window.localStorage.getItem("sessionarea")) {
            this.form.area = JSON.parse(
              window.localStorage.getItem("sessionarea")
            );
          }
          if (window.localStorage.getItem("sessionsale")) {
            this.form.salesman = JSON.parse(
              window.localStorage.getItem("sessionsale")
            );
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    changeoption(item) {
      this.getUserListByType(item);
    },
    handleSelect(item) {
      console.log(item);
    },
    salemanSearch(queryString, cb) {
      var restaurants = this.saleslist;
      var results = restaurants.filter(function (item) {
        return item.name.indexOf(queryString) > -1;
      });

      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    techmanSearch(queryString, cb) {
      var restaurants = this.yanfamanlist;
      var results = restaurants.filter(function (item) {
        return item.name.indexOf(queryString) > -1;
      });

      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    async getUserListByType(type) {
      this.yanfamanlist = [];
      try {
        const res = await getUserListByTypeUrl({
          type: type,
        });
        if (res.data.code === 200) {
          this.yanfamanlist = res.data.data;
        } else {
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    async addorder() {
      this.cando = true;
      if (this.ifshoutian == true && this.shoutianjixing != null) {
        this.form.productModelType = this.shoutianjixing;
      }

      try {
        const res = await AddFaeOrderUrl({
          ...this.form,
          ownerType: this.ownerType,
          attachment: this.fileList.map(({ path }) => path).join(","),
          image: this.imageList.map(({ path }) => path).join(","),
        });
        if (res.data.code === 200) {
          this.$message({
            message: this.$t("chuangjianchenggong"),
            type: "success",
          });

          this.$emit("ChangeAddVisible", false);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        window.localStorage.setItem(
          "sessionarea",
          JSON.stringify(this.form.area)
        );
        window.localStorage.setItem(
          "sessionsale",
          JSON.stringify(this.form.salesman)
        );
        this.cando = false;
      }
    },
    /* 图片增删改查 */
    handleImgUploadSuccess(response, image) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.imageList = [...this.imageList, { ...image, url, path }];
      } else {
        this.imageList = [...this.imageList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    changelist(file, fileList) {
      this.imageList = fileList;
    },
    handleImageRemove(file, imageList) {
      this.imageList = imageList;
    },
    /* 预览 */
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /* 文件增删改查 */
    handleUploadSuccess(response, file) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.fileList = [...this.fileList, { ...file, url, path }];
      } else {
        this.fileList = [...this.fileList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.flex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  ::v-deep.el-form-item__content {
    margin-left: 10px !important;
  }
}

.red {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #f56c6c;
}
.origin {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #e7a23c;
}
.blue {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #409eff;
}
.gray {
  border-radius: 5px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #909399;
}
::v-deep.redparent {
  .is-checked {
    .el-radio__inner {
      border-color: #f56c6c !important;
      background: #f56c6c !important;
    }
  }
}
::v-deep.originparent {
  .is-checked {
    .el-radio__inner {
      border-color: #e7a23c !important;
      background: #e7a23c !important;
    }
  }
}
::v-deep.blueparent {
  .is-checked {
    .el-radio__inner {
      border-color: #409eff !important;
      background: #409eff !important;
    }
  }
}
::v-deep.grayparent {
  .is-checked {
    .el-radio__inner {
      border-color: #909399 !important;
      background: #909399 !important;
    }
  }
}
</style>
